<template>
	<div>
		<common-table
				:itemScope="['lsell', 'configFacility', 'typeManage']"
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				:btnAction="true"
				@search="search"
				@remove="remove"
				@save="save"
				@edit="edit"
				ref="ct"
				pageTitle="酒店"
				:rightConfig="rightConfig"
				:leftConfig="leftConfig"
				:listDeploy="listDeploy"
				:btnActionEvent="btnActionEvent"
				@otherTableOperate="otherTableOperate"
				@otherRightOperate="otherRightOperate"
		>
			<template v-slot:lsell="{ item }">
				<w-switches
						inset
						label=""
						color="var(--v-primary-base)"
						@change="updateStatus(item.sqlid)"
						v-model="item.lsell" hide-details dense/>
			</template>

			<template v-slot:configFacility="{ item }">
				<v-btn depressed color="primary" @click="configFacility(item)">
					<v-icon class="iconfont mr-1" size="16">iconfont icon-guanli-fill</v-icon>
					选择配套
				</v-btn>
			</template>

			<template v-slot:typeManage="{ item }">
				<v-btn depressed color="primary" @click="typeManage(item.code)">
					<v-icon class="iconfont mr-1" size="16">iconfont icon-fangxing</v-icon>
					房型
				</v-btn>
			</template>
		</common-table>

		<template v-if="dialog">
			<exhibition-data-box
					:dialog.sync="dialog"
					title="选择酒店配套"
					:width="412"
					:contentHeight="650"
			>
				<cascade-checkbox
						:height="600"
						@closeDialog="dialog = false"
						searchKey="HOTELKIT"
						:form="hotelObject"
						:api="api"/>
			</exhibition-data-box>
		</template>

		<template v-if="qrObject.dialog">
			<qr-code
					:dialog.sync="qrObject.dialog"
					:param="qrObject.param"
					:subtitle="qrObject.subtitle"
					:pathNo="qrObject.pathNo"/>
		</template>
	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/productManage/hotel.js';
import singleTime from "@/components/dateTime/singleTime.vue";
import exhibitionDataBox from '@/components/exhibition-data-box/exhibition-data-box.vue';
import cascadeCheckbox from "@/components/cascadeCheckbox/cascadeCheckbox.vue";
import qrCode from "@/components/qr-code/qr-code.vue"
import commonTag from "@/components/common-tag/index.vue"
import editorText from '@/components/editorText/editorText.vue';

export default {
	mixins: [commonCurd],
	components: {cascadeCheckbox, exhibitionDataBox, qrCode},
	data() {
		return {
			api,
			hotelObject: {},
			// 排序字段，不填默认sqlid
			sortname: 'seq',
			dialog: false,
			qrObject: {
				dialog: false,
				param: '',
				subtitle: '',
				pathNo: null
			},
			// 定义右侧按钮
			rightConfig: [
				// {label: '同步房型', icon: 'tongbu', event: 'syncRoomType'},
				{label: '新建', icon: 'zengjia', event: 'add'}
			],
			// 定义左侧搜索框
			leftConfig: [{type: 'input', label: '输入酒店代码/名称', property: 'searchkey'}],
			btnActionEvent: [
				{
					event: 'editItem',
					icon: 'bianji2-fill',
					color: 'primary',
					tooltip: '编辑'
				}
				, {
					event: 'deleteItem',
					icon: 'shanchu-fill',
					color: 'var(--v-redness-base)',
					tooltip: '删除'
				},
				{
					event: 'phoneView',
					icon: 'erweima',
					color: 'primary',
					tooltip: '移动端'
				},
				// {
				//   event: 'pcView',
				//   icon: 'diannaoduan',
				//   color: 'primary',
				//   tooltip: '电脑端'
				// }
			],
			// 表格头部字段
			headers: [
				{text: '酒店代码', value: 'code'},
				{text: '酒店名称', value: 'description'},
				{text: '排序号', value: 'seq'},
				{text: '预订状态', value: 'lsell'},
				{text: '配置设施', value: 'configFacility'},
				{text: '房型管理', value: 'typeManage'},
				{text: '操作', value: 'actions', width: 120}
			],
			// 表单字段
			listDeploy: {
				row: [
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'code',
						hasEditDisabledProperty: true,
						connector: {
							props: {
								required: true,
								label: "酒店代码"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'description',
						connector: {
							props: {
								required: true,
								label: "酒店名称"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'address',
						connector: {
							props: {
								required: true,
								label: "酒店地址"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'tel',
						connector: {
							props: {
								required: true,
								label: "酒店电话",
								rules: [
									v => !!v || '酒店电话不能为空',
									v => (/^1[3|4|5|7|8][0-9]\d{8}$/.test(v) || /^(\d{3,4}-)?\d{7,8}$/.test(v)) || '电话格式不正确',
								],
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'coordinate',
						connector: {
							props: {
								label: "地图坐标",
								required: true,
								hint: '可在网上搜索“拾取坐标系统”后获取目标坐标',
								persistentHint: true,
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'seq',
						connector: {
							props: {
								label: "排序号码",
								type: 'number'
							}
						}
					},
					{
						cols: 6,
						subassembly: singleTime,
						property: 'openingtime',
						connector: {
							props: {
								label: "开业日期"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'totalroom',
						connector: {
							props: {
								label: "房间数量",
								type: 'number'
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'groupid',
						connector: {
							props: {
								required: true,
								label: "酒店类型",
								items: [],
								chips: true
							}
						}
					},
					{
						cols: 6,
						subassembly: "WSwitches",
						property: 'lsell',
						disabledDeleteDefault: true,
						connector: {
							props: {
								label: '状态',
								inset: true,
								dense: true
							}
						},
						default: true
					},
					// {
					// 	cols: 12,
					// 	subassembly: 'MaterialFile',
					// 	property: 'mlistpic',
					// 	connector: {
					// 		props: {
					// 			required: true,
					// 			showAddIcon: false,
					// 			hint: '大小不能超过2MB，格式仅限于jpg、png',
					// 			label: '上传图片',
					// 			title: '移动端列表图(686*320)',
					// 			fileType: 'roomType'
					// 		}
					// 	}
					// },
					{
						cols: 12,
						subassembly: 'MaterialFile',
						property: 'mslidepics',
						connector: {
							props: {
								subTitle: '点击添加按钮新增上传区域，拖动选中的文件区域可对其排序，' +
										'图片建议：宽高比例4:3，大小不能超过2MB，格式仅限于jpg、png、jpeg、webp，数量上限为6张',
								label: '上传图片',
								title: '应用端轮播图',
								fileType: 'roomType'
							}
						}
					},
					// {
					// 	cols: 12,
					// 	subassembly: 'MaterialFile',
					// 	property: 'listpic',
					// 	connector: {
					// 		props: {
					// 			required: false,
					// 			showAddIcon: false,
					// 			hint: '大小不能超过2MB，格式仅限于jpg、png',
					// 			label: '上传图片',
					// 			title: '电脑端列表图(1264*713)',
					// 			fileType: 'roomType'
					// 		}
					// 	}
					// },
					// {
					// 	cols: 12,
					// 	subassembly: 'MaterialFile',
					// 	property: 'slidepics',
					// 	connector: {
					// 		props: {
					// 			hint: '大小不能超过2MB，格式仅限于jpg、png',
					// 			label: '上传图片',
					// 			title: '电脑端轮播图(680*340)',
					// 			fileType: 'roomType'
					// 		}
					// 	}
					// },
					// {
					// 	cols: 12,
					// 	subassembly: "MaterialFile",
					// 	property: 'videourl',
					// 	connector: {
					// 		props: {
					// 			title: '视频',
					// 			subTitle: '格式仅限MP4、WebM、Ogg格式',
					// 			limit: 1,
					// 			remark: 2,
					// 			showAddIcon: false
					// 		}
					// 	}
					// },
					{
						cols: 12,
						subassembly: 'div',
						connector: {
							class: 'form-row-title',
							domProps: {
								innerHTML: '酒店标签'
							}
						}
					},
					{
						cols: 12,
						subassembly: commonTag,
						property: 'tags'
					},
					{
						cols: 12,
						subassembly: 'div',
						connector: {
							class: 'form-row-title',
							domProps: {
								innerHTML: '酒店简介'
							}
						}
					},
					{
						cols: 12,
						subassembly: 'VTextarea',
						property: 'introduction',
						connector: {
							props: {
								solo: true,
								rows: 2,
								outlined: true,
								flat: true
							}
						}
					},
					{
						cols: 12,
						subassembly: 'div',
						connector: {
							class: 'form-row-title',
							domProps: {
								innerHTML: '预订须知'
							}
						}
					},
					{
						cols: 12,
						subassembly: editorText,
						property: 'noticetext'
					}
				]
			}
		}
	},
	methods: {
		// 配套
		configFacility(item) {
			this.hotelObject = item;
			this.dialog = true;
		},
		// 房型
		typeManage(item) {
			this.$router.push({name: 'roomType', query: {value: item}});
		},
		otherTableOperate(event, item) {
			if (event === 'phoneView') {
				this.qrObject = {
					dialog: true,
					param: item.code,
					subtitle: item.description,
					pathNo: 2
				}
			}
		},
		otherRightOperate(event) {
			if (event === this.rightConfig[0].event) {
				this.axios.post(this.syncRoomType).then((res) => {
					if (res.code === this.staticVal.Code.Success) {
						this.snackbar.success('同步成功');
					}
				});
			}
		},
	},
	mounted() {
		this.axios.post(this.select_data, {keyname: "hoteltype"}).then((res) => {
			if (res.code === this.staticVal.Code.Success) {
				this.listDeploy.row[8].connector.props.items = res.data[0].values;
			}
		})
	}
}
</script>

<style scoped lang="scss">

</style>
